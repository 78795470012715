import bulmaCalendar from "bulma-calendar";
window.nunjucks = require("nunjucks");
window.htmx = require("htmx.org");
import("htmx.org/dist/ext/client-side-templates");

/* Burger Menu
 ***************************************************************/
function dropdown(el) {
  el.addEventListener("click", () => {
    // Get the target from the "data-target" attribute
    const target = el.dataset.target;
    const $target = document.getElementById(target);

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    el.classList.toggle("is-active");
    $target.classList.toggle("is-active");
  });
}
// Get all "navbar-burger" elements
const $navbarBurgers = document.querySelectorAll(".navbar-burger");
$navbarBurgers.forEach((el) => {
  dropdown(el);
});
// Get all "navbar-burger" elements
const $dropdownMenu = document.querySelectorAll(".has-dropdown");
$dropdownMenu.forEach((el) => {
  dropdown(el);
});

/* Bulma Calendar
 ***************************************************************/
// Initialize all input of date type.
var calendarOptions = {
  type: "datetime",
  color: "primary",
  isRange: false,
  allowSameDayRange: true,
  lang: "en-US",
  startDate: undefined,
  endDate: undefined,
  minDate: null,
  maxDate: null,
  disabledDates: [],
  disabledWeekDays: undefined,
  highlightedDates: [],
  weekStart: 0,
  dateFormat: "dd.MM.yyyy",
  timeFormat: "HH:mm",
  enableMonthSwitch: true,
  enableYearSwitch: true,
  displayYearsCount: 50,
};
// Initialize all input of type date
bulmaCalendar.attach('[type="datetime"]', calendarOptions);
